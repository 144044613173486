'use client';

import { createContext, useContext, useState } from 'react';

type CountryContextType = {
  country: string;
  setCountry: (country: string) => void;
};

const CountryContext = createContext<CountryContextType>({
  country: 'ar',
  setCountry: () => {}
});

export const useCountryContext = () => useContext(CountryContext);

export const CountryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [country, setCountry] = useState('ar');

  return <CountryContext.Provider value={{ country, setCountry }}>{children}</CountryContext.Provider>;
};
