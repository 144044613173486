'use client';

import dynamic from 'next/dynamic';
const ConfirmationModal = dynamic(() =>
  import('../components/ConfirmationModal').then((mod) => mod.ConfirmationModal)
);
import React, { FunctionComponent, createContext, useContext, useState } from 'react';

type ConfirmationStatus = 'info' | 'warning' | 'danger';

interface ModalConfig {
  status?: ConfirmationStatus;
  title?: string;
  content?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  onClose?: () => void;
  onConfirm?: () => void;
}

type ConfirmationContextType = {
  confirm: (modalConfig: ModalConfig) => void;
};

const ConfirmationContext = createContext<ConfirmationContextType>({
  confirm: (modalConfig: ModalConfig) => modalConfig
});

interface Props {
  children: React.ReactNode;
}

export const ConfirmationProvider: FunctionComponent<Props> = ({ children }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState<ModalConfig>({
    status: 'info',
    title: 'Confirmar Cambios',
    content: '¿Quieres confirmar la acción?',
    message: '',
    confirmText: 'Si, guardar',
    cancelText: 'No, volver'
  });

  return (
    <ConfirmationContext.Provider
      value={{
        confirm: (modalConfig: ModalConfig) => {
          setShowConfirmation(true);
          setConfirmOptions({ ...confirmOptions, ...modalConfig });
        }
      }}
    >
      {showConfirmation && (
        <ConfirmationModal {...confirmOptions} setShowConfirmation={setShowConfirmation} />
      )}
      {children}
    </ConfirmationContext.Provider>
  );
};

export const useConfirmation = (): ConfirmationContextType => useContext(ConfirmationContext);
