const ENV = process.env.NODE_ENV;

const domain = ENV !== 'development' ? '.agora.red' : 'localhost';

export const createCookie = (name: string, value: any, date: Date) => {
  const expirey = date instanceof Date ? '; expires=' + date : null;
  const cookie = [name, '=', JSON.stringify(value), '; domain=', domain, '; path=/;', expirey].join('');
  document.cookie = cookie;
};

export const deleteCookie = (name: string): void => {
  document.cookie = [name, '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain=', domain].join('');
};
