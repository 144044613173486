'use client';

import dynamic from 'next/dynamic';
const AuthModal = dynamic(() => import('../components/AuthModal').then((mod) => mod.AuthModal));
import { useLocalStorage } from '@/hooks';
import { AuthModalState } from '@/types';
import React, { FunctionComponent, createContext, useContext, useState } from 'react';

type AuthModalContextType = {
  showAuthModal: (authType: 'signup' | 'signin', isVendor: boolean) => void;
};

const AuthModalContext = createContext<AuthModalContextType>({
  showAuthModal: (authType: 'signup' | 'signin') => authType
});

interface Props {
  children: React.ReactNode;
}

export const AuthModalProvider: FunctionComponent<Props> = ({ children }) => {
  const [authModalOptions, setAuthModalOptions] = useState<AuthModalState>({
    showModal: false,
    authType: 'signup',
    isVendor: false
  });
  const redirectUri = useLocalStorage<string | null>('redirectUri', null);

  return (
    <AuthModalContext.Provider
      value={{
        showAuthModal: (authType, isVendor) => {
          redirectUri.setValue(window.location.pathname + window.location.search);
          setAuthModalOptions({
            showModal: true,
            authType: authType,
            isVendor
          });
        }
      }}
    >
      {authModalOptions.showModal && (
        <AuthModal authModalState={authModalOptions} setAuthModalState={setAuthModalOptions} />
      )}
      {children}
    </AuthModalContext.Provider>
  );
};

export const useAuthModal = (): AuthModalContextType => useContext(AuthModalContext);
