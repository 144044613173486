import { trackGenericEvent } from '@/utils/analytics';
import { createCookie, deleteCookie } from '@/utils/cookies';
import auth0 from 'auth0-js';
import dayjs from 'dayjs';

const CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || 'INSERT_CLIENT_ID';
const DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || 'INSERT_AUTH0_DOMAIN';
const AUTH0_NIGHTWING_REDIRECT_URL =
  process.env.NEXT_PUBLIC_AUTH0_NIGHTWING_REDIRECT_URL || 'http://localhost:3000/callback';
const AUTH0_FLASH_REDIRECT_URL =
  process.env.NEXT_PUBLIC_AUTH0_FLASH_REDIRECT_URL || 'http://localhost:3001/callback';
const AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || 'INSERT_AUTH0_AUDIENCE';

const webAuth = new auth0.WebAuth({
  domain: DOMAIN,
  clientID: CLIENT_ID,
  audience: AUDIENCE,
  responseType: 'token',
  overrides: {
    __tenant: 'devagora',
    __token_issuer: `https://${DOMAIN}/`
  },
  redirectUri: AUTH0_NIGHTWING_REDIRECT_URL
});

export const logIn = (
  email: string,
  password: string,
  isVendor: boolean,
  errorHandler: (err: auth0.Auth0Error | null) => void
): void => {
  const { state, nonce } = sendAuthInfoToFlash();

  webAuth.login(
    {
      realm: 'Username-Password-Authentication', //connection name or HRD domain
      username: email,
      password,
      redirectUri: isVendor ? AUTH0_FLASH_REDIRECT_URL : AUTH0_NIGHTWING_REDIRECT_URL,
      state,
      nonce
    },
    (err) => {
      errorHandler(err);
    }
  );
};

export const register = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  isVendor: boolean,
  errorHandler: (err: auth0.Auth0Error | null) => void
): void => {
  const utmParams = getUtmParams();
  console.log('🚀 ~ utmParams:', utmParams);

  webAuth.signup(
    {
      username: email,
      password,
      email,
      userMetadata: {
        first_name: firstName,
        last_name: lastName,
        utm_params: JSON.stringify(utmParams)
      },
      connection: 'Username-Password-Authentication'
    },
    (err) => {
      if (!err) {
        logIn(email, password, isVendor, errorHandler);
      } else {
        errorHandler(err);
      }
    }
  );
};

export const authorize = (isVendor: boolean, connection: 'facebook' | 'google-oauth2'): void => {
  const { state, nonce } = sendAuthInfoToFlash();
  trackGenericEvent('Button Social Media Sign In Clicked', { connection });
  const utmParams = getUtmParams();

  const redirectUri = isVendor
    ? appendUtmToRedirectUri(AUTH0_FLASH_REDIRECT_URL, utmParams)
    : AUTH0_NIGHTWING_REDIRECT_URL;

  console.log('🚀 ~ authorize ~ utmParams:', utmParams);
  console.log('🚀 ~ authorize ~ redirectUri:', redirectUri);

  webAuth.authorize({
    connection,
    redirectUri,
    state,
    nonce,
    appState: {
      utmParams
    }
  });
};

export const parseHash = (
  errorHandler: (err: auth0.Auth0Error | null) => void,
  successHandler: () => void
): void =>
  webAuth.parseHash({ hash: window.location.hash }, (err) => {
    if (err) {
      errorHandler(err);
    } else {
      successHandler();
    }
  });

export const changePassword = (
  email: string,
  errorHandler: (err: auth0.Auth0Error | null) => void,
  successHandler: () => void
): void =>
  webAuth.changePassword({ email, connection: 'Username-Password-Authentication' }, (err) => {
    if (err) errorHandler(err);
    else successHandler();
  });

const appendUtmToRedirectUri = (baseUri: string, utmParams: Record<string, string>) => {
  const redirectUri = new URL(baseUri);
  Object.entries(utmParams).forEach(([key, value]) => {
    redirectUri.searchParams.append(key, value);
  });
  return redirectUri.toString();
};

const randomString = () => {
  const length = 32;
  const bytes = new Uint8Array(length);
  const result = [];
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';

  const crypto = window.crypto;
  const random = crypto.getRandomValues(bytes);
  for (let i = 0; i < random.length; i += 1) result.push(charset[random[i] % charset.length]);

  return result.join('');
};

const sendAuthInfoToFlash = (): { state: string; nonce: string } => {
  const payload = {
    state: randomString(),
    nonce: randomString()
  };

  const yearly = dayjs().add(1, 'year').toDate();
  createCookie('authInfo', JSON.stringify(payload), yearly);

  return { state: payload.state, nonce: payload.nonce };
};

export const removeAuthInfoToFlash = (): void => {
  deleteCookie('authInfo');
};

function getSession() {
  return new Promise((resolve, reject) => {
    webAuth.checkSession({}, (err, authResult) => {
      if (err) {
        reject(err);
      } else {
        resolve(authResult);
      }
    });
  });
}

export async function getUserInfo() {
  const authResult: any = await getSession();
  return new Promise((resolve, reject) => {
    webAuth.client.userInfo(authResult.accessToken, (err, userInfo) => {
      if (err) {
        reject(err);
      } else {
        resolve(userInfo);
      }
    });
  });
}

const getUtmParams = () => {
  const utmParams = {};
  const urlParams = new URLSearchParams(window.location.search);
  for (const [key, value] of urlParams.entries()) {
    if (key.startsWith('utm_')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      utmParams[key] = value;
    }
  }
  return utmParams;
};
