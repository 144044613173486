'use client';

import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@/styles/fonts.css';
import '@/styles/schedule.css';
import '@/styles/global.css';

import React, { useEffect } from 'react';
import GlobalProviders from '@/contexts/global';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

interface Props {
  children: React.ReactNode;
}
export default function RootLayout({ children }: Props) {
  useEffect(() => {
    let gtmDidInit = false;

    const initGTM = () => {
      if (gtmDidInit) {
        return;
      }

      gtmDidInit = true;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-5W6HFVH`;
      script.onload = () => {
        window.dataLayer.push({
          event: 'gtm.js',
          'gtm.start': new Date().getTime(),
          'gtm.uniqueEventId': 0
        });
      };
      script.onerror = () => {
        console.error('Failed to load GTM');
      };

      document.head.appendChild(script);
    };

    const initGTMOnEvent = (event: Event) => {
      initGTM();
      document.removeEventListener(event.type, initGTMOnEvent);
    };

    document.addEventListener('scroll', initGTMOnEvent);
    document.addEventListener('mousemove', initGTMOnEvent);
    document.addEventListener('touchstart', initGTMOnEvent);

    return () => {
      document.removeEventListener('scroll', initGTMOnEvent);
      document.removeEventListener('mousemove', initGTMOnEvent);
      document.removeEventListener('touchstart', initGTMOnEvent);
    };
  }, []);

  return (
    <html translate="no" lang="es" data-theme="light" className="scroll-smooth hover:scroll-auto" id="top">
      <head>
        <meta content="es" />
        <meta name="google" content="notranslate" />
        {/* Preconnect to third-party domains */}
        <link rel="preconnect" href="https://cyclone.agora.red" />
        <link rel="preconnect" href="https://auth.agora.red" />
        <link rel="preconnect" href="https://d3p2i4pt6coq3o.cloudfront.net" />

        {/* Fallback to dns-prefetch for older browsers */}
        <link rel="dns-prefetch" href="https://cyclone.agora.red" />
        <link rel="dns-prefetch" href="https://auth.agora.red" />
        <link rel="dns-prefetch" href="https://d3p2i4pt6coq3o.cloudfront.net" />

        <link
          rel="icon"
          type="image/png"
          href="https://d3p2i4pt6coq3o.cloudfront.net/landing/favicon.ico"
          sizes="32x32"
        />
      </head>
      <body className="notranslate">
        <GlobalProviders>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
          {children}
        </GlobalProviders>
      </body>
    </html>
  );
}
