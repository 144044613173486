type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

const sizes: Record<Size, string> = {
  xxs: '400px',
  xs: '480px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1536px',
  xxxl: '1920px'
};

export const theme = {
  fontFamily: 'Arboria',
  font: {
    size: {
      xs: '0.6875rem',
      sm: '0.75rem',
      base: '0.9375rem',
      lg: '1rem',
      xl: '1.25rem',
      xxl: '1.4375rem',
      xxxl: '2rem'
    },
    weight: {
      light: '300',
      book: '400',
      medium: '500',
      bold: '700',
      black: '900'
    }
  },
  rounded: {
    sm: '0.125rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem'
  },
  colors: {
    black: '#212121',
    white: '#ffffff',
    whiteSmoke: '#f5f5f5',
    blue: {
      100: '#d3e3ff',
      200: '#a9ffe7',
      300: '#7ba9ff',
      400: '#3faed7',
      500: '#0072FF',
      600: '#4267B2',
      700: '#014AA4',
      800: '#005BCC'
    },
    red: {
      100: '#FF9CA5',
      200: '#FF4658',
      300: '#AD2D39'
    },
    grey: {
      100: '#ECEEF0',
      200: '#e9e9e9',
      300: '#e2eaf2',
      400: '#dcdcdc',
      500: '#DADCE0',
      600: '#CECECE',
      700: '#858585',
      800: '#626262',
      900: '#505050'
    },
    yellow: {
      100: '#fff3be',
      200: '#FFE77B',
      300: '#FFE166'
    },
    green: {
      100: '#33df97',
      200: '#0ad6a1',
      300: '#25d366',
      400: '#15b572'
    },
    pink: '#FFE3E6',
    opacityBlack: '#00000080'
  },
  bookingActions: {
    finishedEvent: '#AFC6F2',
    bookableEvent: '#48E3A2',
    fullEvent: '#09D5A1',
    pausedEvent: '#FFE77B',
    canceledEvent: '#FFE3E6',
    workingHourEvent: '#7ba9ff',
    individualEvent: '#48E3A2',
    finishedText: '#0072ff',
    bookableText: '#FFF',
    pausedText: '#00000080',
    canceledText: '#FF4658',
    bookableStatus: '#A9FFE7',
    bookableStatusText: '#09D5A1',
    workingHourText: '#FFF',
    uniqueEvent: '#858585',
    individualText: '#FFF',
    bookableBorder: '#09D5A1',
    uniqueBorder: '#212121',
    individualBorder: '#09D5A1',
    workingHourBorder: '#0072FF'
  },
  shadow: {
    sm: '0 1px 2px rgba(0,0,0,0.05), 0 1px 1px rgba(0, 0, 0, 0.07)',
    md: '0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.06)',
    lg: '0 4px 3px rgba(0, 0, 0, 0.07), 0 2px 2px rgba(0, 0, 0, 0.06)',
    xl: '0 20px 13px rgba(0, 0, 0, 0.03), 0 8px 5px rgba(0, 0, 0, 0.08)'
  },
  breakpoint: (size: Size): string => `@media (max-width: ${sizes[size]})`,
  navbar: {
    desktop: {
      home: {
        background: 'transparent',
        font: '#fff'
      },
      noHome: {
        background: '#fff',
        font: '#212121'
      }
    },
    dropdown: {
      background: '#fff',
      font: '#212121'
    },
    mobile: {
      background: '#fff',
      font: '#212121',
      secFont: '#868686'
    }
  }
};

export type ThemeType = typeof theme;
