import { AuthModalProvider, AuthProvider, ConfirmationProvider, SuccessSplashProvider } from '@/contexts';
import { theme } from '@/theme';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { FunctionComponent } from 'react';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { CountryProvider } from './country';

const CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || 'PGZb7ilWlcImtSsaeZKh83G1PMH7UI4N';
const DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || 'devagora.us.auth0.com';
const AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || 'http://auth.agora.dev';

const queryClient = new QueryClient();

interface Props {
  children: React.ReactNode;
}

const GlobalProviders: FunctionComponent<Props> = ({ children }) => {
  return (
    <Auth0Provider domain={DOMAIN} clientId={CLIENT_ID} audience={AUDIENCE}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          draggable={false}
          theme="colored"
        />
        <QueryClientProvider client={queryClient}>
          <ConfirmationProvider>
            <AuthModalProvider>
              <SuccessSplashProvider>
                <AuthProvider>
                  <CountryProvider>{children}</CountryProvider>
                </AuthProvider>
              </SuccessSplashProvider>
            </AuthModalProvider>
          </ConfirmationProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Auth0Provider>
  );
};

export default GlobalProviders;
