import dynamic from 'next/dynamic';
const SuccessSplash = dynamic(() => import('../components/SuccessSplash').then((mod) => mod.SuccessSplash));
import { SuccessSplashState } from '@/types';
import React, { FunctionComponent, createContext, useContext, useState } from 'react';

type SuccessSplashOptions = {
  message: string;
  mainButtonText: string;
  mainButtonAction: () => void;
};

type SuccessSplashContextType = {
  showSuccessSplash: (successSplashOptions: SuccessSplashOptions) => void;
};

const SuccessSplashContext = createContext<SuccessSplashContextType>({
  showSuccessSplash: (successSplashOptions: SuccessSplashOptions) => successSplashOptions
});

type Props = {
  children: React.ReactNode;
};

export const SuccessSplashProvider: FunctionComponent<Props> = ({ children }) => {
  const [successSplashOptions, setSuccessSplashOptions] = useState<SuccessSplashState>({
    showSplash: false,
    message: '',
    mainButtonText: '',
    mainButtonAction: () => null
  });

  return (
    <SuccessSplashContext.Provider
      value={{
        showSuccessSplash: (successSplashOptions: SuccessSplashOptions) => {
          setSuccessSplashOptions({
            showSplash: true,
            message: successSplashOptions.message,
            mainButtonAction: successSplashOptions.mainButtonAction,
            mainButtonText: successSplashOptions.mainButtonText
          });
        }
      }}
    >
      {successSplashOptions.showSplash && (
        <SuccessSplash
          successSplashState={successSplashOptions}
          setSuccessSplashState={setSuccessSplashOptions}
        />
      )}
      {children}
    </SuccessSplashContext.Provider>
  );
};

export const useSuccessSplash = (): SuccessSplashContextType => useContext(SuccessSplashContext);
