import { useAuthModal } from './authModal';
import { removeAuthInfoToFlash } from '@/auth';
import { useClient, useLocalStorage } from '@/hooks';
import { SessionI } from '@/types/cyclone/requests';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import React, { FunctionComponent, createContext, useCallback, useContext, useEffect, useMemo } from 'react';

const NIGHTWING_URL = process.env.NEXT_PUBLIC_NIGHTWING_URL;

export interface AuthContextAuthenticatedI {
  isAuthenticated: true;
  isLoading: boolean;
  session: SessionI;
  reloadSession: () => void;
  logOut: () => void;
  logIn: ({ redirectUri, isVendor }: { redirectUri?: string; isVendor: boolean }) => void;
  isAuth0Loading: boolean;
}

export interface AuthContextNotAuthenticatedI {
  isAuthenticated: false;
  isLoading: boolean;
  session: null;
  reloadSession: () => void;
  getAccessTokenSilently?: () => Promise<string>;
  logOut: () => void;
  logIn: ({ redirectUri, isVendor }: { redirectUri?: string; isVendor: boolean }) => void;
  isAuth0Loading: boolean;
}

export type AuthContextI = AuthContextAuthenticatedI | AuthContextNotAuthenticatedI;

const AuthContext = createContext<AuthContextI>({
  isAuthenticated: false,
  isAuth0Loading: false,
  isLoading: false,
  session: null,
  reloadSession: () => null,
  logOut: () => null,
  logIn: () => null
});
AuthContext.displayName = 'AuthContext';

type Props = {
  children: React.ReactNode;
};

export const AuthProvider: FunctionComponent<Props> = ({ children }) => {
  const { user, getAccessTokenSilently, isAuthenticated, logout, isLoading: isAuth0Loading } = useAuth0();
  const { showAuthModal } = useAuthModal();
  const { client } = useClient();
  const session = useLocalStorage<SessionI | null>('session', null);
  const redirectUri = useLocalStorage<string | null>('redirectUri', null);

  /**
   * Auth0 React Library do not share the method to check the session, we need to call getAccessTokenSilently
   * to retrieve the session, ignore the error, it will be thrown each time the session is not authenticated
   */
  const checkAuth0Session = async () => {
    try {
      await getAccessTokenSilently();
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    checkAuth0Session();
  }, [isAuthenticated]);

  const isDifferentUser = user?.email !== session.storedValue?.email;

  const { isLoading, refetch } = useQuery(
    ['session'],
    async () => await client<SessionI>('sessions', 'GET', { isAuthRequired: true }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: user !== undefined && (session.storedValue === null || isDifferentUser),
      onSuccess: (data: any) => session.setValue({ ...data, isAuthenticated: true }),
      // FIXME: Handle onError case
      onError: () => null
    }
  );

  const logOut = useCallback(() => {
    redirectUri.setValue(window.location.pathname + window.location.search);

    session.deleteValue('session');
    // resetUser();
    // trackGenericEvent('User Logged Out');
    logout({
      returnTo: `${NIGHTWING_URL}/logout`
    });
    removeAuthInfoToFlash();

    // window.location.href = window.location.pathname + window.location.search;
  }, [session, logout]);

  const logIn = useCallback(
    (args: any) => {
      redirectUri.setValue(
        args.redirectUri ? args.redirectUri : window.location.pathname + window.location.search
      );
      showAuthModal('signin', args.isVendor);
    },
    [redirectUri, showAuthModal]
  );

  const value = useMemo((): AuthContextI => {
    const contextValue = {
      isAuthenticated: true,
      isLoading,
      session: session.storedValue,
      getAccessTokenSilently,
      logOut,
      logIn,
      reloadSession: refetch,
      isAuth0Loading
    };
    if (!isAuthenticated) {
      const result: AuthContextNotAuthenticatedI = { ...contextValue, isAuthenticated: false, session: null };
      return result;
    } else {
      const result: AuthContextAuthenticatedI = {
        ...contextValue,
        isAuthenticated: true,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        session: session.storedValue!
      };
      return result;
    }
  }, [isAuthenticated, isLoading, session, getAccessTokenSilently, logOut, logIn, isAuth0Loading, refetch]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextI => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};
